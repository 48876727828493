/*Page Timeline*/
.timeline-cover{
	height:330px;
	width:100%;
	position:fixed;
	z-index:1;
	top:0px;
	background-position:center center;
	background-size:cover;
    margin-top:50px;
}
.timeline-cover .overlay{opacity:0.8;}
.timeline-cover .content{z-index:10;}
.timeline-body{
    position:relative;
	margin-top:200px;
	padding-top:5px;
	z-index:2;
	background-color:#f0f0f0;
	padding-bottom:10px;
    box-shadow:none!important;
}
.timeline-header{height:55px; border-bottom:solid 1px rgba(255,255,255,0.5);}
.timeline-header .back-button{
	position:absolute;
	top:0px;
	left:0px;
	width:50px;
	height:50px;
	text-align:center;
	line-height:50px;
	font-size:16px;
	color:#FFF;
	z-index:11;
}
.timeline-header .menu-icon{
	position:absolute;
	top:2px;
	right:0px;
	width:50px;
	height:50px;
	text-align:center;
	line-height:50px;
	font-size:16px;
	color:#FFF;
	z-index:11;
}
.timeline-header .menu-icon em{background-color:#FFF;}
.timeline-logo{
	background-position:center;
	background-repeat:no-repeat;
	position:absolute;	
	z-index:10;
	width:100%;
}
.timeline-deco{
	background-color:rgba(0,0,0,0.08);
	position:absolute;
	top:0px;
    z-index:0;
	bottom:0px;
	left:43px;
	width:1px;
}
.timeline-item-content, .timeline-item-content-full{
	border:solid 1px rgba(0,0,0,0.1);
	background-color:#FFF;
	margin:0px 15px 30px 90px;
	padding:20px 15px;
}
.timeline-item{position:relative; z-index:2;}
.timeline-item-content-full{padding:0px;}
.timeline-item-content-full h5{padding:5px 15px 15px 15px;}
.timeline-item .timeline-icon{
    position:absolute;
	width:45px;
	height:45px;
    left:21px;
	line-height:45px;
	border-radius:45px;
	top:25px;
	font-size:18px;
	text-align:center;
}

/*Timeline Center*/
.timeline-cover-center .timeline-sub-heading,
.timeline-cover-center .timeline-heading{
	padding-left:0px; text-align:center;
}
.timeline-cover-center .timeline-image{
	top:50%;
	bottom:auto;
	margin-top:-150px;
	left:50%;
	margin-left:-60px;
	background-size:120px 120px;
	width:120px;
	height:120px;
	border-radius:100px;
}
.timeline-body-center{padding-bottom:40px;}
.timeline-body-center .timeline-icon{width:60px; height:60px; left:50%; line-height:60px; margin-left:-31px; margin-top:-115px;}
.timeline-body-center .timeline-deco{left:50%;	margin-left:-1px;}
.timeline-body-center .timeline-item-content,
.timeline-body-center .timeline-item-content-full
{margin:120px 20px 20px 20px!important; padding:20px;}
